<template>
  <!-- 营销活动 -->
  <div>
    <keep-alive>
      <component :is="myComponent" :key="compkey"></component>
    </keep-alive>
  </div>
</template>
<script>
export default {
  components: {
    openMainModule: () => import("./ac_group.vue"),
    openAc1Module: () => import("./ac1_pinTuan.vue"),
    pinTuanAddModule: () => import("./chiledrens/pinTuan_add.vue"),
    kanJiaAddModule: () => import("./chiledrens/kanjia_add.vue"),
    miaoShaAddModule: () => import("./chiledrens/miaosha_add.vue"),
  },
  data() {
    return {
      compkey: 0,
    };
  },
  mounted() {},
  watch: {
    "$store.state.chooseModule.indexKey"() {
      this.compkey = this.$store.state.chooseModule.indexKey;
    },
  },
  computed: {
    myComponent() {
      return this.$store.state.chooseModule.value
        ? this.$store.state.chooseModule.value
        : "openMainModule";
    },
  },
  methods: {},
};
</script>
<style scoped lang="less"></style>
